import { useEffect, useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import { Stack } from '@mui/material'

import {
  OurStories,
  CompetitionTilesGrid,
  DrawTodayCompetitionTilesGrid,
  InstaWinCompetitionTilesGrid
} from '@components/ui/organisms'
import {
  SocialProofNumbers,
  TrustReviewsContainer,
  HomeCarousel,
  Collapsible,
  HowItWorks
} from '@components/ui/molecules'
import { Title } from '@components/ui/atoms'

import { splitCompetitions } from '@helpers/competitions/splitCompetitions'

import {
  PageTypeEnum,
  sendAppsFlyerListViewChannelMessage
} from '@utils/channels/appsFlyerChannel'

import { QueryClientKeysEnum } from '@enums'

import {
  CompetitionStatus,
  CompetitionsService,
  TestimonialsService
} from '@elitecompetitions/core-api'

import { StyledContainer } from './Home.styled'

const competitionsParams = {
  filterStatus: CompetitionStatus.ACTIVE,
  filterIsInvisible: false,
  take: -1
}

const Home = props => {
  const { homeContent, socialStatNumbers } = props

  const { data: testimonials = [] } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_TESTIMONIALS],
    queryFn: TestimonialsService.getAllTestimonials
  })

  const {
    data: activeCompetitions = splitCompetitions([]),
    isFetched = false
  } = useQuery({
    queryKey: [QueryClientKeysEnum.GET_COMPETITIONS, competitionsParams],
    queryFn: () => CompetitionsService.getCompetitions(competitionsParams),
    select: ({ data }) => splitCompetitions(data)
  })

  const canSortByHighValue = useMemo(() => {
    const { carousel, drawnToday } = activeCompetitions

    return !carousel.overRideCarousel && drawnToday.length > 0
  }, [activeCompetitions])

  useEffect(() => {
    if (isFetched) {
      ;(async () => {
        await sendAppsFlyerListViewChannelMessage({
          pageType: PageTypeEnum.HOME_PAGE,
          competitions: activeCompetitions.all
        })
      })()
    }
  }, [isFetched])

  return (
    <StyledContainer>
      <Stack
        spacing={{
          sm: 10,
          xs: 5
        }}
      >
        <Stack
          spacing={{
            sm: 6,
            xs: 3
          }}
        >
          <section>
            <HomeCarousel
              drawnTodayCompetitions={activeCompetitions.carousel.competitions}
              sortByHighValue={canSortByHighValue}
            />
          </section>

          <section>
            <SocialProofNumbers socialStatNumbers={socialStatNumbers} />
          </section>
        </Stack>

        <section>
          <HowItWorks />
        </section>

        <Stack
          spacing={{
            sm: 6,
            xs: 3
          }}
        >
          {activeCompetitions.drawnToday.length > 0 && (
            <section>
              <DrawTodayCompetitionTilesGrid
                heading="Online Competitions Drawn Today"
                drawnTodayCompetitions={activeCompetitions.drawnToday}
              />
            </section>
          )}

          {activeCompetitions.instaWins.length > 0 && (
            <section>
              <InstaWinCompetitionTilesGrid
                instaWinCompetitions={activeCompetitions.instaWins}
              />
            </section>
          )}

          {activeCompetitions.featuredAndFree.length > 0 && (
            <section>
              <CompetitionTilesGrid
                heading="Featured Competitions"
                competitions={activeCompetitions.featuredAndFree}
              />
            </section>
          )}

          {activeCompetitions.other.length > 0 && (
            <section>
              <CompetitionTilesGrid
                heading="More Competitions"
                competitions={activeCompetitions.other}
              />
            </section>
          )}

          {activeCompetitions.saturdaySesh.length > 0 && (
            <section>
              <CompetitionTilesGrid
                heading="Winner Wednesdays"
                competitions={activeCompetitions.saturdaySesh}
              />
            </section>
          )}

          {activeCompetitions.soldOut.length > 0 && (
            <section>
              <CompetitionTilesGrid
                heading="Sold Out Competitions"
                competitions={activeCompetitions.soldOut}
              />
            </section>
          )}
        </Stack>

        <Stack
          spacing={{
            sm: 8,
            xs: 4
          }}
        >
          {testimonials.length > 0 && (
            <section>
              <OurStories values={testimonials} />
            </section>
          )}

          <TrustReviewsContainer />
        </Stack>

        <Stack direction="column" width="100%" gap="16px">
          <Title variant="h2">Online Competitions</Title>

          <Collapsible content={homeContent} />
        </Stack>
      </Stack>
    </StyledContainer>
  )
}

export default Home
