import { isNil } from 'lodash-es'

import { InferGetServerSidePropsType } from 'next'

import { useCallback, useEffect } from 'react'

import { useRouter } from 'next/router'

import { dehydrate, QueryClient } from '@tanstack/react-query'

import Home from '@components/ui/organisms/Home'
import Head from '@components/Head'

import { getLocalAssetFromCDN, prepareSocialProofNumText } from '@helpers'

import { getHomePageContent, getSocialStatsContent } from '@api/WebsiteContent'

import { QueryClientKeysEnum } from '@enums'

import {
  CompetitionsService,
  CompetitionStatus,
  TestimonialsService
} from '@elitecompetitions/core-api'

const competitionsParams = {
  filterStatus: CompetitionStatus.ACTIVE,
  filterIsInvisible: false,
  take: -1
}

const HomePage = (
  props: InferGetServerSidePropsType<typeof getServerSideProps>
) => {
  const { websiteContents, socialStatNumbers, homeContent } = props

  const { title, keywords, description } = websiteContents || {}

  const router = useRouter()

  const prefetch = useCallback(async () => {
    await router.prefetch('/competitions/[slug]')
  }, [])

  useEffect(() => {
    window.addEventListener('load', prefetch)

    return () => {
      window.removeEventListener('load', prefetch)
    }
  }, [])

  return (
    <>
      <Head
        title={title || 'Elite Competitions - Win unbelievable big prizes!'}
        description={
          description ||
          'With Elite competitions, buy multiple tickets to win big prizes. For a little price, you get to win big prizes like cars, houses, accessories, cash and more! Every competition has a guaranteed winner'
        }
        keywords={keywords || null}
        url="/"
      >
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "image": "${getLocalAssetFromCDN('/assets/logos/og-logo.png')}",
                "url": "https://elitecompetitions.co.uk/",
                "sameAs": [
                  "https://www.facebook.com/elitecompltd/",
                  "https://twitter.com/elitecompltd",
                  "https://www.instagram.com/elitecompetitionsltd",
                  "https://www.youtube.com/channel/UCdqJoP6_7pXuid2xr-Hz3Yg",
                  "https://www.tiktok.com/@elite_competitions"
                ],
                "logo": "${getLocalAssetFromCDN(
                  '/assets/logos/main-logo.svg'
                )}",
                "name": "Elite Competitions",
                "description": "Elite Competitions give you the chance to win cars, cash, tech, and homes. Scores of UK winners each month. Thousands of reviews.",
                "email": "info@elitecompetitions.co.uk",
                "telephone": "01253 836633",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Unit 19, 20 & Unit 21, Kincraig Business Park, Kincraig Road",
                  "addressLocality": "Blackpool",
                  "addressCountry": "UK",
                  "addressRegion": "Lancashire",
                  "postalCode": "FY2 0PJ"
                },
                "vatID": "FR12345678901"
              }
            `
          }}
        />
      </Head>

      <Home socialStatNumbers={socialStatNumbers} homeContent={homeContent} />
    </>
  )
}

export const getServerSideProps = async () => {
  const queryClient = new QueryClient()

  try {
    const [socialStatsData, homePageData] = await Promise.all([
      queryClient.ensureQueryData({
        queryKey: [QueryClientKeysEnum.GET_SOCIAL_STATS_CONTENT],
        queryFn: getSocialStatsContent
      }),
      queryClient.ensureQueryData({
        queryKey: [QueryClientKeysEnum.GET_HOMEPAGE_CONTENT],
        queryFn: getHomePageContent
      }),
      queryClient.prefetchQuery({
        queryKey: [QueryClientKeysEnum.GET_TESTIMONIALS],
        queryFn: TestimonialsService.getAllTestimonials
      }),
      queryClient.prefetchQuery({
        queryKey: [QueryClientKeysEnum.GET_COMPETITIONS, competitionsParams],
        queryFn: () => CompetitionsService.getCompetitions(competitionsParams)
      })
    ])

    const socialStatsResult = (socialStatsData || {}).content || {}
    const socialStatNumbers = {
      followers: isNil(socialStatsResult.followers)
        ? '210,000+'
        : prepareSocialProofNumText(socialStatsResult.followers),
      prizesWon: isNil(socialStatsResult.prizesWon)
        ? '£50M+'
        : `£${prepareSocialProofNumText(socialStatsResult.prizesWon)}`,
      winners: isNil(socialStatsResult.winners)
        ? '7,000+'
        : prepareSocialProofNumText(socialStatsResult.winners, false),
      reviews: socialStatsResult.reviews
    }

    return {
      props: {
        websiteContents: (homePageData || {}).content || {},
        socialStatNumbers,
        homeContent: socialStatsResult.homeContent || '',
        dehydratedState: dehydrate(queryClient)
      }
    }
  } catch (error) {
    console.error('Error in getinital props home', error)
  }

  return {
    props: {
      websiteContents: {}
    }
  }
}

export default HomePage
