import { Container, styled } from '@mui/material'

export const StyledContainer = styled(Container)`
  margin-top: 16px;
  margin-bottom: 40px;

  @media (min-width: 600px) {
    margin-bottom: 80px;
  }
`
